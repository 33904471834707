import { motion } from 'framer-motion'
import { useIntersectionObserver } from 'usehooks-ts'

import Counter from '@/components/counter'
import RichText from '@/components/ui/rich-text'
import colors from '@/constants/colors'
import { useMemo } from 'react'
import ImageContentful from './ui/image-contentful'
import { cx } from '@/utils/strings'

const { primaryBlue, deepBlue, indigoBlue, lightGray, white } = colors

export type AverageMarkerProps = {
  averageValue: number
  index: number
  isCompact?: boolean
}

export function AverageMarker({ averageValue, index, isCompact }: AverageMarkerProps) {
  return (
    <motion.div
      className={cx(
        'w-[2px] flex flex-col items-center absolute top-[calc(-50%-14px)] md:top-[calc(-50%+2px)] z-10',
        isCompact ? 'top-[calc(-50%-2px)] md:!top-[calc(-50%+10px)]' : 'top-[calc(-50%-14px)] md:!top-[calc(-50%+2px)]'
      )}
      style={{ left: `${averageValue}%` }}
      variants={{
        0: { opacity: 0, translateY: -30, translateX: '-50%' },
        100: { opacity: 1, translateY: 0, translateX: '-50%' },
      }}
      transition={{ duration: 0.4, ease: 'easeOut', delay: 1.5 + (index + 1) * 0.1 }}
    >
      <span className={cx('whitespace-nowrap', isCompact && 'h-5 md:h-[22px]')}>AVG: {averageValue}%</span>
      <div
        className={cx('w-[3px] rounded-full bg-tealBlue', isCompact ? 'h-[48px] md:h-[80px]' : 'h-[64px] md:h-[104px]')}
      />
    </motion.div>
  )
}

export type BarGraphItemProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string
  value: number
  averageValue?: number
  logoIcon?: Queries.AssetFragment
  index: number
  isActive?: boolean
  color?: typeof indigoBlue | typeof primaryBlue | typeof lightGray
  backgroundColor?: typeof deepBlue | typeof lightGray | typeof white
  isCompact?: boolean
}

export function BarGraphItem({
  value,
  averageValue,
  index,
  logoIcon,
  isActive,
  color,
  backgroundColor,
  isCompact,
}: BarGraphItemProps) {
  return (
    <div className="flex items-center">
      {logoIcon && (
        <div className="w-8 h-8 md:w-12 md:h-12 mr-4 md:mr-6">
          <ImageContentful {...logoIcon.fields} />
        </div>
      )}
      <div className="relative flex items-center flex-1">
        {averageValue && <AverageMarker averageValue={averageValue} index={index} isCompact={isCompact} />}
        <div
          className={cx(
            'relative w-full rounded-full overflow-hidden',
            isCompact ? 'h-[42px] md:h-[72px]' : 'h-[48px] md:h-[78px]',
            backgroundColor === deepBlue && 'bg-deepBlue',
            backgroundColor === lightGray && 'bg-gray-100',
            backgroundColor === white && 'bg-white'
          )}
        >
          <motion.div
            style={{ width: `${value}%` }}
            className={cx(
              'absolute top-0 left-0 h-full bg-gradient-to-r from-transparent to-100% origin-left rounded-br-full rounded-tr-full',
              color === indigoBlue && 'to-indigoBlue',
              color === primaryBlue && 'to-primaryBlue',
              color === lightGray && 'from-gray-300 to-gray-300'
            )}
            variants={{
              0: { translateX: '-100%' },
              100: { translateX: 0 },
            }}
            transition={{ duration: 1.5, ease: 'anticipate' }}
          />
        </div>
      </div>
      <div className="relative ml-4">
        <Counter
          className="absolute top-0 bottom-0 left-0 right-0 m-auto w-full h-[40px] text-2xl"
          from={0}
          to={value}
          duration={1.5}
          postfix="%"
          isActive={isActive}
        />
      </div>
    </div>
  )
}

export type BarGraphProps = {
  headline?: string
  items: Queries.BarGraphStatFragment[]
  bottomCaption?: any
  backgroundColor?: typeof primaryBlue | typeof indigoBlue | typeof lightGray | typeof white
}

export default function BarGraph({
  headline,
  stats: items,
  bottomCaption,
  backgroundColor = primaryBlue,
}: BarGraphProps) {
  const { entry, ref } = useIntersectionObserver({ threshold: 0.5, freezeOnceVisible: true })
  const isVisible = !!entry?.isIntersecting

  const barColors = useMemo(() => {
    switch (backgroundColor) {
      case primaryBlue:
        return [
          { color: indigoBlue, backgroundColor: deepBlue },
          { color: lightGray, backgroundColor: deepBlue },
          { color: lightGray, backgroundColor: deepBlue },
        ]
      case indigoBlue:
        return [
          { color: primaryBlue, backgroundColor: deepBlue },
          { color: lightGray, backgroundColor: deepBlue },
          { color: lightGray, backgroundColor: deepBlue },
        ]
      case lightGray:
        return [
          { color: primaryBlue, backgroundColor: white },
          { color: lightGray, backgroundColor: white },
          { color: lightGray, backgroundColor: white },
        ]
      case white:
        return [
          { color: primaryBlue, backgroundColor: lightGray },
          { color: lightGray, backgroundColor: lightGray },
          { color: lightGray, backgroundColor: lightGray },
        ]
      default:
        return [
          { color: primaryBlue, backgroundColor: white },
          { color: lightGray, backgroundColor: white },
          { color: lightGray, backgroundColor: white },
        ]
    }
  }, [backgroundColor])

  return (
    <div
      ref={ref}
      className={cx(
        'rounded-3xl  bg-gradient-to-b  @xs:w-full',
        !headline && 'pt-8 lg:pt-16',
        backgroundColor === primaryBlue && 'bg-primaryBlue text-white',
        backgroundColor === indigoBlue && 'bg-indigoBlue text-white',
        backgroundColor === lightGray && 'bg-gray-100 text-indigoBlue',
        backgroundColor === white && 'bg-white text-indigoBlue'
      )}
    >
      {headline && (
        <h2 className="text-3xl text-center pt-12 px-4 mb-8 @xs:!mb-4 @xs:!text-3xl md:text-[2.5rem] md:mb-16">
          {headline}
        </h2>
      )}
      <motion.div
        className="flex flex-col gap-8 pt-0 pr-[90px] pb-[77px] pl-[40px] lg:!gap-12 lg:!pt-0px lg:pl-[120px] lg:pr-[160px] lg:!pb-[77px] @xs:!pl-8 @xs:!pr-16 @xs:!py-4"
        transition={{ staggerChildren: 0.1 }}
        initial="0"
        animate={isVisible ? '100' : '0'}
      >
        {items?.map((item, index) => {
          const {
            fields: {
              id,
              label,
              value,
              averageValue,
              averageValue2,
              averageValue3,
              logoIcon,
              logoIcon2,
              logoIcon3,
              value2,
              value3,
            },
          } = item

          return (
            <div key={id || label}>
              <h4 className={cx('mb-9 md:mb-4 @xs:text-xl font-normal', logoIcon && 'ml-8 md:ml-[72px]')}>{label}</h4>
              <div className={cx('flex flex-col', averageValue2 || averageValue3 ? 'gap-y-8' : 'gap-y-6')}>
                <BarGraphItem
                  index={index}
                  value={value}
                  averageValue={averageValue}
                  logoIcon={logoIcon}
                  isActive={isVisible}
                  color={barColors[0].color}
                  backgroundColor={barColors[0].backgroundColor}
                  isCompact={!!(value2 || value3)}
                />
                {value2 && (
                  <BarGraphItem
                    index={index}
                    value={value2}
                    averageValue={averageValue2}
                    logoIcon={logoIcon2}
                    isActive={isVisible}
                    color={barColors[1].color}
                    backgroundColor={barColors[1].backgroundColor}
                    isCompact
                  />
                )}
                {value3 && (
                  <BarGraphItem
                    index={index}
                    value={value3}
                    averageValue={averageValue3}
                    logoIcon={logoIcon3}
                    isActive={isVisible}
                    color={barColors[2].color}
                    backgroundColor={barColors[2].backgroundColor}
                    isCompact
                  />
                )}
              </div>
            </div>
          )
        })}
      </motion.div>

      {bottomCaption?.content && (
        <div className="text-center pb-4 [a]:text-tealBlue">
          <RichText body={bottomCaption} />
        </div>
      )}
    </div>
  )
}
