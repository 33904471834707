/* eslint-disable import/no-cycle */

import { useState, useEffect } from 'react'
import Spinner from '@/icons/bwi/spinner.svg'
import { isPrint } from '@/utils/print'
import './hubilo-form.css'

export default function HubiloForm({ id, formId }): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const targetId = `hbl-${id}`

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.hubilo.com/ticketing/widget/static/js/ticketing-widget.js'
    script.async = true
    document.body.appendChild(script)
    script.onload = () => setLoaded(true)
  }, [])

  useEffect(
    () => () => {
      // remove the style tag we had to apply when component unmounts

      window['@hubilotechnologies/ticketing-micro-fe'].init({
        container: document.getElementById(targetId),
        props: {
          communityUrl: `https://events.hubilo.com/${formId}`,
          languageCode: 'EN',
          showEventInfo: false,
          hideEventLogo: false,
          hideEventSocialLinks: false,
          isWidgetExpand: true,
          environment: 'production',
        },
      })
      document.getElementsByTagName('html')[0].setAttribute('style', 'font-size: 16px !important')
      setScriptLoaded(true)
    },
    [loaded]
  )

  useEffect(() => {
    if (!document.getElementById(targetId)) {
      document.getElementsByTagName('html')[0].removeAttribute('style')
    }
  }, [])

  if (isPrint()) return null
  return (
    <div className="min-h-[10rem]">
      {!loaded && (
        <div className="w-full flex justify-center p-8">
          <Spinner className="animate-spin h-16 w-16 text-gray-400" />
        </div>
      )}

      <div id={targetId} />
    </div>
  )
}
