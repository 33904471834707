/* eslint-disable react/require-default-props */
import { useCallback, type ReactNode } from 'react'
import type { Entry } from 'contentful'

import ImageContentful from '@/components/ui/image-contentful'
import Video from '@/components/ui/video'
import DynamicAction, { type DynamicActionProps } from '@/components/ui/dynamic-action'
import Link from '@/components/link-component'
import RichText from '@/components/ui/rich-text'
import HubiloForm from '@/components/ui/hubilo-form/hubilo-form'
import HubSpotForm from '@/components/ui/hubspot-form/hubspot-form'
import Image from '@/components/ui/image'
import Lottie from '@/components/lottie'
import BarGraph from '@/components/bar-graph'
import Code from '@/components/ui/code/code'
import CodeTabs from '@/components/ui/code/code-tabs'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'
import type {
  TypeComponentCardSkeleton,
  TypeComponentCtaLinkSkeleton,
  TypeComponentHubSpotFormSkeleton,
  TypeComponentLottieSkeleton,
  TypeResourceImageSkeleton,
  TypeSectionBarGraphSkeleton,
  TypeSectionSetOfCardsSkeleton,
} from '@/types/ctf'

export type DynamicCardImage = {
  image: {
    description: string
    publicUrl: string
    title: string
  }
}

type CtaLinkEntry = Entry<TypeComponentCtaLinkSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type CardEntry = Entry<TypeComponentCardSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type SetOfCardsEntry = Entry<TypeSectionSetOfCardsSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type ResourceImageEntry = Entry<TypeResourceImageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type LottieEntry = Entry<TypeComponentLottieSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type BarGraphEntry = Entry<TypeSectionBarGraphSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type HubspotFormEntry = Entry<TypeComponentHubSpotFormSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type CardProps = Omit<CardEntry['fields'], 'actions' | 'subtext' | 'internalName'> &
  Omit<SetOfCardsEntry['fields'], 'actions' | 'internalName'> & {
    subtext?: string
    actions?: DynamicActionProps[]
    columnCount?: number
    isHero: boolean
  }

export default function Card({
  actions,
  asset,
  autoAdjustSvgColor,
  backgroundColor,
  cardIconLocation,
  cardIconSize,
  columnCount = 1,
  dropShadow,
  headline,
  image,
  isHero = false,
  removeImagePadding,
  removeTextPadding,
  stackImageOverText,
  subtext,
  subtextAlignment,
  tagline,
  unconstrained,
}: CardProps) {
  const soleAction: any =
    actions?.length === 1 && actions.at(0)?.type !== 'componentHubspotCta'
      ? (actions[0] as CtaLinkEntry & { to: string })
      : null

  const soleActionLink =
    (soleAction as any)?.link?.sys?.contentType?.sys?.id === 'componentLink'
      ? (soleAction as any)?.link?.fields?.url
      : (soleAction as { to: string })?.to

  const hasLinkArrow = soleAction && !soleAction?.label && !!soleActionLink

  const isSvg =
    (image?.fields?.file?.contentType || asset?.fields?.image?.fields?.file?.contentType) === 'image/svg+xml'

  const { primaryBlue, lightGray, indigoBlue, white, transparent } = colors
  const LinkWrap = useCallback(
    ({
      children,
      to,
      plausibleEventName = '',
    }: {
      children: ReactNode
      to?: string | null | false
      plausibleEventName: string
    }) => (
      <>
        {to ? (
          <Link className={`flex w-full ${plausibleEventName?.trim()}`} to={to} aria-label={`go to ${to}`}>
            {children}
          </Link>
        ) : (
          children
        )}
      </>
    ),
    []
  )

  const Tagline = useCallback(
    ({ twStuff }) => {
      const Wrapper = isHero ? 'h1' : 'div'
      return (
        <Wrapper data-testid="card-tagline" className={cx('pl-1 pb-2 text-sm uppercase tracking-[0.3em]', twStuff)}>
          {tagline}
        </Wrapper>
      )
    },
    [tagline, isHero]
  )

  const LinkRenderer = useCallback((node: { data: { uri: string } }, children: ReactNode) => {
    return (
      <Link
        className={cx(
          backgroundColor === white && `text-primaryBlue`,
          backgroundColor === lightGray && `text-primaryBlue`,
          backgroundColor === white && `text-primaryBlue`,
          backgroundColor === lightGray && `text-primaryBlue`,
          backgroundColor === primaryBlue && `text-white underline!`,
          backgroundColor === indigoBlue && `text-tealBlue`
        )}
        to={node.data.uri}
      >
        {children}
      </Link>
    )
  }, [])
  const renderImageContentful = () => (
    <ImageContentful
      {...(image?.fields as any)}
      className={cx(
        'w-full rounded-xl overflow-hidden',
        hasLinkArrow && `rounded-2xl`,
        backgroundColor === white && `bg-white`,
        backgroundColor === lightGray && `bg-gray-100`,
        backgroundColor === primaryBlue && `bg-primaryBlue`,
        backgroundColor === indigoBlue && `bg-indigoBlue`,
        backgroundColor === transparent && 'bg-transparent',
        !isSvg && `rounded-xl`,
        isSvg && `!w-auto [&_svg]:h-full [&_svg]:w-full`,
        isSvg && !autoAdjustSvgColor && `[&_svg]:text-transparent`,
        isSvg &&
          autoAdjustSvgColor &&
          (backgroundColor === indigoBlue || backgroundColor === primaryBlue
            ? `[&_svg]:text-white`
            : `[&_svg]:text-indigoBlue`),
        isSvg && !headline && !subtext && !asset && !actions && `max-w-[1000px]`,
        isSvg && tagline === 'Resource' && `!bg-transparent`,
        isSvg && tagline === 'Blog' && '!bg-transparent !p-6',
        'dark:!bg-transparent'
      )}
      loading={isHero ? 'eager' : 'lazy'}
    />
  )

  const Headline = isHero && tagline ? 'div' : 'h1'

  return (
    <div className={cx('@container flex', unconstrained && 'pb-[1px]')}>
      <LinkWrap to={hasLinkArrow && soleActionLink} plausibleEventName={soleAction?.plausibleEventName}>
        <div
          className={cx(
            'flex flex-col @3xl:items-center @3xl:space-x-10 h-full w-full',
            unconstrained && isHero && 'pt-5 pb-10 lg:py-16',
            unconstrained && !isHero && columnCount >= 2 && 'py-6 lg:py-16',
            unconstrained && !isHero && columnCount === 1 && 'py-6 sm:py-10',
            !unconstrained && 'p-6 sm:p-10 rounded-4xl before:rounded-4xl',
            cardIconLocation === 'Right' ? '@3xl:flex-row-reverse @3xl:space-x-reverse' : '@3xl:flex-row',
            backgroundColor === primaryBlue && 'text-white bg-primaryBlue',
            backgroundColor === indigoBlue && 'text-white bg-indigoBlue',
            backgroundColor === lightGray && 'text-indigoBlue bg-gray-100',
            backgroundColor === white && 'text-indigoBlue bg-white',
            backgroundColor === transparent && 'bg-transparent',
            dropShadow && `shadow-even-around dark:shadow-even-around-dark-mode`,
            stackImageOverText === false && '!flex-row gap-8 items-center',
            ((asset as any)?.sys?.contentType?.sys?.id === 'componentHubSpotForm' ||
              (asset as any)?.sys?.contentType?.sys?.id === 'componentHubiloForm') &&
              '@3xl:!items-start',
            ((asset as any)?.sys?.contentType?.sys?.id === 'componentHubSpotForm' ||
              (asset as any)?.sys?.contentType?.sys?.id === 'componentHubiloForm') &&
              cardIconLocation === 'Right' &&
              'flex-col-reverse',

            hasLinkArrow &&
              !unconstrained &&
              `relative transition-transform duration-300 ease-out md:hover:scale-[1.02] md:before:content-[""]
              before:absolute before:-z-10 before:top-0 before:left-0 before:h-full before:w-full before:shadow-2xl before:shadow-black before:opacity-0 before:md:hover:opacity-30
              before:transition-opacity before:duration-[250ms] before:ease-out`,
            // Opacity hover style (unconstrained)
            hasLinkArrow && unconstrained && `transition-opacity duration-[250ms] ease-out md:hover:opacity-80`,
            'dark:bg-transparent dark:text-white'
          )}
          data-testid="card-container"
        >
          {tagline && <Tagline twStuff={`mb-0 w-full @3xl:hidden`} />}

          {(image || asset) && (
            <div
              data-testid="card-asset"
              className={cx(
                `flex justify-center self-center relative`,
                asset?.sys?.contentType?.sys?.id === 'componentCodeTabs' && 'w-full flex-[1 0 auto]',
                asset?.sys?.contentType?.sys?.id === 'componentCode' && 'lg:[&_.bw-code-container]:max-h-[38rem]',
                (stackImageOverText || stackImageOverText === undefined) && `mb-4 @3xl:mb-0`,
                cardIconSize === '1/4' && '@3xl:w-1/4',
                cardIconSize === '1/3' && '@3xl:w-1/3',
                cardIconSize === '1/2' && '@3xl:w-1/2',
                cardIconSize === 'Full' && 'w-full @3xl:w-1/2',
                cardIconSize === '2/3' && '@3xl:w-2/3',
                cardIconSize === '3/4' && '@3xl:w-3/4',
                isSvg && cardIconSize === '1/4' && `w-1/4`,
                isSvg && cardIconSize === '1/3' && `w-1/3`,
                isSvg && cardIconSize === '1/2' && `w-1/2`,
                isSvg && cardIconSize === 'Full' && `w-1/2`,
                ((isSvg && tagline === 'Resource') || (isSvg && tagline === 'Blog')) && 'w-full',
                columnCount === 1 && !removeImagePadding && `@3xl:p-6 @3xl:xl:p-10`,
                columnCount === 1 && !removeImagePadding && isHero && `@3xl:px-6 @3xl:xl:px-12 @3xl:py-0 @3xl:xl:py-0`,
                !headline && !subtext && !image && !actions && asset && `!w-full`,
                !headline && !subtext && !asset && !actions && isSvg && `!w-full mb-4`,
                isHero && `hidden @3xl:sm:block`,
                ((asset as any)?.sys?.contentType?.sys?.id === 'componentHubSpotForm' ||
                  (asset as any)?.sys?.contentType?.sys?.id === 'componentHubiloForm') &&
                  `!self-start block !py-10 @3xl:!py-0`,
                cardIconSize === '1/4' && `!self-start  @3xl:self-center`,
                !subtext && columnCount >= 2 && `self-center`,
                asset?.sys?.contentType?.sys?.id === 'sectionBarGraph' && '!w-full'
              )}
            >
              {image ? (
                (isSvg && tagline === 'Resource') || (isSvg && tagline === 'Blog') ? (
                  <div className="rounded-xl bg-primaryBlue [&>*]:aspect-video w-full">{renderImageContentful()}</div>
                ) : (
                  renderImageContentful()
                )
              ) : (
                <>
                  {asset?.sys?.contentType?.sys?.id === 'componentHubSpotForm' && (
                    <div
                      className={cx(
                        'w-full rounded-4xl p-9 bg-white text-indigoBlue sticky top-0',
                        backgroundColor === white && `bg-gray-100`
                      )}
                    >
                      <HubSpotForm {...(asset as HubspotFormEntry)?.fields} />
                    </div>
                  )}
                  {asset?.sys?.contentType?.sys?.id === 'componentHubiloForm' && (
                    <div className={cx('w-full', backgroundColor === white && 'bg-gray-100')}>
                      <HubiloForm formId={asset.fields.formId} id={asset.sys.id} />
                    </div>
                  )}

                  {asset?.sys?.contentType?.sys?.id === 'componentVideo' && <Video {...(asset as any)} />}

                  {asset?.sys?.contentType?.sys?.id === 'resourceImage' && (
                    <Image
                      image={(asset as ResourceImageEntry)?.fields?.image}
                      {...asset}
                      className={cx(
                        'w-full rounded-xl',
                        hasLinkArrow && `rounded-2xl`,
                        backgroundColor === white && `bg-white`,
                        backgroundColor === lightGray && `bg-gray-100`,
                        backgroundColor === primaryBlue && `bg-primaryBlue`,
                        backgroundColor === indigoBlue && `bg-indigoBlue`,
                        !isSvg && `rounded-xl`,
                        isSvg && `[&_svg]:h-full [&_svg]:w-full`,
                        isSvg && !autoAdjustSvgColor && `[&_svg]:text-transparent`,
                        isSvg &&
                          autoAdjustSvgColor &&
                          (backgroundColor === indigoBlue || backgroundColor === primaryBlue
                            ? `[&_svg]:text-white`
                            : `[&_svg]:text-indigoBlue`),
                        isSvg && !headline && !subtext && !asset && !actions && `max-w-[1000px]`,
                        isSvg && tagline === 'Resource' && `bg-transparent`
                      )}
                      loading={isHero ? 'eager' : 'lazy'}
                    />
                  )}
                  {asset?.sys?.contentType?.sys?.id === 'componentLottie' && (
                    <Lottie {...(asset as LottieEntry)?.fields} />
                  )}
                  {asset?.sys?.contentType?.sys?.id === 'sectionBarGraph' && (
                    <BarGraph {...(asset as BarGraphEntry)?.fields} />
                  )}
                  {asset?.sys?.contentType?.sys?.id === 'componentCode' && <Code {...asset?.fields} />}
                  {asset?.sys?.contentType?.sys?.id === 'componentCodeTabs' && <CodeTabs {...asset?.fields} />}
                </>
              )}
              {hasLinkArrow && tagline?.length && (
                <div
                  className={cx(
                    'w-10 h-10 absolute bottom-0 bg-white rounded-full flex justify-center items-center m-4 opacity-50 shadow',
                    columnCount === 1 && !removeImagePadding
                      ? `right-0 @3xl:bottom-6 @3xl:right-6 @3xl:xl:bottom-10 @3xl:xl:right-10`
                      : `right-0`
                  )}
                >
                  <span className="font-bwi text-primaryBlue">&#xe91b;</span>
                </div>
              )}
            </div>
          )}

          <div
            data-testid="card-content"
            className={cx(
              columnCount === 1 && !removeTextPadding && `@3xl:p-6 @3xl:xl:p-10`,
              unconstrained && `@3xl:!p-0`,
              cardIconSize === '1/4' && `@3xl:w-3/4`,
              cardIconSize === '1/3' && `@3xl:w-2/3`,
              cardIconSize === '1/2' && `@3xl:w-1/2`,
              cardIconSize === 'Full' && `w-full @3xl:w-1/2`,
              cardIconSize === '2/3' && `@3xl:w-1/3`,
              cardIconSize === '3/4' && `@3xl:w-1/4`,
              !headline && !subtext && !asset && !actions && `hidden`,
              asset && !headline && !subtext && !image && !actions && `hidden`,
              !asset && !image && isHero && `text-center xl:w-4/5 m-auto`,
              !subtext && `text-center @3xl:text-left`
            )}
          >
            {tagline && <Tagline twStuff={`mb-1 hidden @3xl:block`} />}
            {headline && (
              <div data-testid="card-headline">
                {isHero ? (
                  <Headline className="h1 leading-tight mb-4 hyphens-auto firefox:hyphens-manual card-hero-h1">
                    {headline}
                  </Headline>
                ) : !isHero && columnCount === 1 ? (
                  <h3 className="h4">{headline}</h3>
                ) : (
                  <h3 className="h5">{headline}</h3>
                )}
              </div>
            )}

            <div
              data-testid="card-subtext"
              className={cx(
                '[&_h2]:h3',
                !actions && `[&_p:last-of-type]:mb-0`,
                subtextAlignment === 'Center' && 'text-center',
                subtextAlignment === 'Right' && 'text-right'
              )}
            >
              {typeof subtext === 'string' && <p>{subtext}</p>} {/* Blog Page/Landing Page cards pass a string */}
              {typeof subtext === 'object' && (
                <RichText
                  body={subtext}
                  a={LinkRenderer}
                  renderImage={(ref) => <Image image={ref} showCaption={false} />}
                  backgroundColor={backgroundColor}
                />
              )}
            </div>
            {!hasLinkArrow && actions && (
              <div>
                <div
                  className={cx(
                    'flex flex-wrap gap-4',
                    !subtext && `justify-center @3xl:justify-start`,
                    !asset && !image && isHero && `justify-center`
                  )}
                >
                  {actions.map((action) => {
                    return (
                      <DynamicAction
                        key={action.id}
                        {...action}
                        backgroundColor={backgroundColor}
                        data-testid="card-cta"
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </LinkWrap>
    </div>
  )
}
