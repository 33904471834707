import { useEffect, ReactNode } from 'react'
import { useMotionValue, useTransform, animate, motion } from 'framer-motion'

export type CounterProps = {
  className?: string
  from: number
  to: number
  duration: number
  postfix?: ReactNode
  prefix?: ReactNode
  isActive?: boolean
}

export default function Counter({ className, from, to, duration, postfix, prefix, isActive = false }: CounterProps) {
  const count = useMotionValue(from)
  const rounded = useTransform(count, (latest) => Math.round(latest))

  useEffect(() => {
    if (isActive) {
      const controls = animate(count, to, { duration })
      return controls.stop
    }
  }, [isActive])

  return (
    <div className={className}>
      <span>{prefix}</span>
      <motion.span>{rounded}</motion.span>
      <span>{postfix}</span>
    </div>
  )
}
