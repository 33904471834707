/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, type ReactNode } from 'react'
import type { Entry } from 'contentful'
import { useLiveUpdates } from '@/utils/live-preview'

import Card, { type CardProps } from '@/components/ui/card'
import RichText from '@/components/ui/rich-text'
import DynamicAction, { type DynamicActionProps } from '@/components/ui/dynamic-action'

import { cx, slugify } from '@/utils/strings'
import colors from '@/constants/colors'
import { Experience } from '@ninetailed/experience.js-react'
import { mapNtExperiences } from '@/utils/ninetailed'

import PricingCard, { type PricingCardProps } from '@/components/ui/pricing-card'
import Image from '@/components/ui/image'
import CodeTabs from '@/components/ui/code/code-tabs'
import Spinner from '@/icons/bwi/spinner.svg'
import type {
  TypeBlogPageSkeleton,
  TypeComponentCardSkeleton,
  TypeComponentPricingSkeleton,
  TypeLandingPageSkeleton,
  TypeResourceImageSkeleton,
  TypeResourcePageSkeleton,
  TypeResourceVideoPageSkeleton,
  TypeSectionSetOfCardsSkeleton,
} from '@/types/ctf'
import { usePage } from '@inertiajs/react'

type CardEntry = Entry<TypeComponentCardSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type BlogPageEntry = Entry<TypeBlogPageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type ResourcePageEntry = Entry<TypeResourcePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type SetOfCardsEntry = Entry<TypeSectionSetOfCardsSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type SetOfCardsProps = SetOfCardsEntry['fields'] & {
  isHero: boolean
  pageColor: string
  pageOrderNo: number
  entryId?: string
}

// eslint-disable-next-line react/function-component-definition
export const SetOfCardsSectionComponent = ({ isHero, pageColor, pageOrderNo, entryId, ...fields }: SetOfCardsProps) => {
  const {
    fields: {
      actionsPosition,
      internalName,
      headline,
      body,
      cards,
      actions,
      backgroundColor,
      cardIconSize,
      cardIconLocation,
      cardsPerColumn,
      dropShadow,
      stackImageOverText,
      unconstrained,
    },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  // if cards per column is not explicitly set, as long as there are 6 or fewer cards, show all cards in a single row. Otherwise default to 3 per row.
  const columnCount = cardsPerColumn || (Array.isArray(cards) && cards?.length <= 6 ? cards?.length : 3) || 1
  const { primaryBlue, lightGray, indigoBlue, white, transparent } = colors
  const HeadingWrap = useCallback(
    ({ children }: { children: ReactNode }) => (
      <>
        {pageOrderNo === 1 ? (
          <h2 className={cx('text-center font-normal h3', isHero && 'h1')}>{children}</h2>
        ) : (
          <h3 className={cx('text-center font-normal', isHero && 'h1')}>{children}</h3>
        )}
      </>
    ),
    []
  )

  const renderActions = () => (
    <div className="flex flex-wrap justify-center gap-6 my-6">
      {actions?.map((action) => {
        if (!action) return null
        const type = action.sys.contentType.sys.id
        const fields = {
          type,
          ...action.fields,
        } as DynamicActionProps
        return (
          <DynamicAction key={action?.sys.id} {...fields} backgroundColor={backgroundColor} data-testid="card-cta" />
        )
      })}
    </div>
  )

  return (
    <section
      id={slugify(internalName)}
      className={cx(
        isHero && unconstrained && `!mt-0`,
        unconstrained && backgroundColor === white && `bg-white`,
        unconstrained && backgroundColor === lightGray && `bg-gray-100`,
        unconstrained && backgroundColor === primaryBlue && `bg-primaryBlue`,
        unconstrained && backgroundColor === indigoBlue && `bg-indigoBlue`,
        unconstrained && backgroundColor === transparent && `bg-transparent`,
        'dark:bg-transparent dark:text-white'
      )}
    >
      {(headline || body) && (
        <div
          className={cx(
            'max-w-4xl mx-auto text-indigoBlue px-5 mb-12 dark:text-white',
            (backgroundColor === indigoBlue || backgroundColor === primaryBlue) && unconstrained && `text-white`,
            (pageColor === indigoBlue || pageColor === primaryBlue) && !unconstrained && `text-white`,
            backgroundColor !== pageColor && unconstrained && `pt-8 lg:pt-16`
          )}
        >
          {headline && <HeadingWrap>{headline}</HeadingWrap>}
          {body && (
            <div className="text-center">
              <RichText body={body} />
              {actions && actionsPosition === 'Above cards' && renderActions()}
            </div>
          )}
        </div>
      )}
      <div>
        {cards && (
          <div
            className={cx(
              'max-w-8xl mx-auto px-5 lg:px-9 grid gap-5 xl:gap-12 grid-cols-1',
              columnCount === 1 && `lg:grid-cols-1`,
              columnCount === 2 && `lg:grid-cols-2`,
              columnCount === 3 && `lg:grid-cols-3`,
              columnCount === 4 && `lg:grid-cols-4`,
              columnCount === 5 && `lg:grid-cols-5`,
              columnCount === 6 && `lg:grid-cols-6`,
              actions && `mb-10 lg:mb-20`
            )}
          >
            {Array.isArray(cards) &&
              cards.map((card, idx) => {
                if (!card) return null
                let cardProps: Partial<CardProps> = {}

                switch (card.sys.contentType.sys.id) {
                  case 'componentCard':
                    cardProps = card.fields as CardProps
                    const actions = (card.fields as CardEntry['fields']).actions as
                      | CardEntry['fields']['actions']
                      | DynamicActionProps[]
                    if (actions) {
                      cardProps.actions = actions
                        .map((action) => {
                          if (!action) return null
                          if ((action as DynamicActionProps).type) return action as DynamicActionProps
                          const data = action as Exclude<typeof action, DynamicActionProps | undefined>
                          const type = data.sys?.contentType.sys.id
                          const fields = {
                            type,
                            id: data.sys.id,
                            ...data.fields,
                          } as DynamicActionProps
                          return fields
                        })
                        .filter((action) => !!action) as DynamicActionProps[]
                    }
                    break
                  case 'blogPage':
                    const blogCard = card as BlogPageEntry
                    cardProps = {
                      tagline: 'Blog',
                      headline: blogCard.fields.title,
                      subtext: blogCard.fields.description,
                      image: blogCard.fields.heroImage,
                      actions: [
                        {
                          id: blogCard.sys.id,
                          to: `/blog/${blogCard.fields.slug}`,
                        },
                      ],
                    }
                    break
                  case 'resourcePage':
                    const resourceCard = card as ResourcePageEntry
                    cardProps = {
                      tagline: 'Resource',
                      headline: resourceCard.fields.title,
                      subtext: resourceCard.fields.description,
                      image: resourceCard.fields.image || resourceCard.fields.seo?.fields.ogImage,
                      actions: [
                        {
                          type: 'ctaLink',
                          id: resourceCard.sys.id,
                          to: `/resources/${resourceCard.fields.slug}`,
                        },
                      ],
                    }
                    break
                  case 'resourceVideoPage':
                    const resourceVideoCard = card as Entry<TypeResourceVideoPageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
                    cardProps = {
                      tagline: 'Video',
                      headline: resourceVideoCard.fields.title,
                      subtext: resourceVideoCard.fields.image?.fields.description,
                      image: resourceVideoCard.fields.image || resourceVideoCard.fields.seo?.fields.ogImage,
                      actions: [
                        {
                          type: 'ctaLink',
                          id: resourceVideoCard.sys.id,
                          to: `/resources/videos/${resourceVideoCard.fields.slug}`,
                        },
                      ],
                    }
                    break
                  case 'landingPage':
                    const pageCard = card as Entry<TypeLandingPageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
                    cardProps = {
                      headline: pageCard?.fields.seo?.fields.title,
                      subtext: pageCard?.fields.seo?.fields.description,
                      image: pageCard?.fields.seo?.fields.ogImage,
                      actions: [
                        {
                          type: 'ctaLink',
                          id: pageCard.sys.id,
                          to: `/${pageCard.fields.slug}`,
                        },
                      ],
                    }
                    break
                  case 'componentCodeTabs':
                    ;<CodeTabs key={card.sys.id} code={(card as any).fields.code} />
                    break
                  case 'componentPricing':
                    const {
                      fields: { price, features, actions: pricingCardActions, ...pricingCard },
                    } = card as Entry<TypeComponentPricingSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
                    const pricingCardProps: PricingCardProps = {
                      ...pricingCard,
                      price,
                      features: features as string[],
                      actions: pricingCardActions,
                      hasShadow: true,
                    }
                    return <PricingCard key={card.sys.id} {...pricingCardProps} />
                  case 'resourceImage':
                    return (
                      <Image
                        key={card.sys.id}
                        {...(card.fields as Entry<TypeResourceImageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>['fields'])}
                      />
                    )
                  default:
                    break
                }
                return (
                  <Card
                    key={card.sys.id}
                    {...cardProps}
                    {...{
                      backgroundColor,
                      cardIconSize,
                      cardIconLocation:
                        columnCount === 1 && idx % 2 === 1
                          ? ({ Right: 'Left', Left: 'Right' }[cardIconLocation] as typeof cardIconLocation)
                          : cardIconLocation,
                      columnCount,
                      dropShadow,
                      isHero,
                      stackImageOverText,
                      unconstrained,
                    }}
                  />
                )
              })}
          </div>
        )}
        {actions && actionsPosition !== 'Above cards' && renderActions()}
      </div>
    </section>
  )
}

export default function SetOfCardsSection(props: any): JSX.Element {
  const { nt_experiences, id, isHero, pageColor, pageOrderNo } = props
  const Loader = useCallback(
    () => (
      <div className="w-full flex justify-center items-center p-8 h-[600px]">
        <Spinner className="animate-spin h-20 w-20 text-gray-400" />
      </div>
    ),
    []
  )
  return (
    <Experience
      {...props}
      id={id}
      experiences={mapNtExperiences(nt_experiences)}
      component={SetOfCardsSectionComponent}
      passthroughProps={{ isHero, pageColor, pageOrderNo }}
      loadingComponent={Loader}
    />
  )
}
